import axios from 'axios'
import { BASEURL } from '../const'
import Store from '../../store'

import { successHandler, errorHandler } from '../handlers'

const URL = `${BASEURL}/users`

export default {
  user() {
    return {
      getAll: async ({ offset = 0 }) => {
        const endpoint = `${URL}?offset=${offset}&limit=10`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      getAUser: async ({ userId }) => {
        const endpoint = `${URL}/${userId}`
        try {
          const res = await axios.get(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      addUser: async ({ email, role, firstName, lastName }) => {
        const endpoint = `${URL}`
        const data = { email, role, firstName, lastName }
        try {
          const res = await axios.post(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      updateUserStatus: async ({ userId, status }) => {
        const endpoint = `${URL}/${userId}/status`
        const data = { status }
        try {
          const res = await axios.put(endpoint, data, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      },
      deleteAUser: async ({ userId }) => {
        const endpoint = `${URL}/${userId}`
        try {
          const res = await axios.delete(endpoint, {
            headers: {
              ...Store.getters.getRequestHeaders.headers
            }
          })
          return successHandler(res)
        } catch (error) {
          return errorHandler(error)
        }
      }
    }
  }
}
