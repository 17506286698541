<template>
  <p class="text-red-500 text-xs my-2 font-semibold" v-if="error === true">
    *{{ message }}
  </p>
</template>

<script>
export default {
  name: 'ErrorText',
  props: {
    error: { type: Boolean },
    message: { type: String }
  }
}
</script>

<style></style>
