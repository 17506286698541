<template>
  <div>
    <t-modal v-model="createStaffDialog">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Create Staff</h4>
        <p class="text-sm font-light">Create a new staff here</p>
        <form @submit.prevent="addNewStaff" class="py-2">
          <error-text
            :message="error.message"
            :error="error.status"
          ></error-text>
          <div class="mt-4 grid md:grid-cols-2 grid-cols-1 gap-8">
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff First Name</label
              >
              <t-input
                v-model="newStaff.firstName"
                :disable="loading"
                class="focus:outline-none"
                type="text"
                placeholder="First Name"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff Last Name</label
              >
              <t-input
                v-model="newStaff.lastName"
                class="focus:outline-none"
                :disable="loading"
                type="text"
                placeholder="Last Name"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff Email</label
              >
              <t-input
                v-model="newStaff.email"
                class="focus:outline-none"
                type="text"
                :disable="loading"
                placeholder="Staff Email"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <!-- <div class="form-control">
            <label
              class="text-xs font-semibold text-gray-800 mb-2 block"
              for="name"
              >Staff Phone Number</label
            >
            <t-input
              v-model="newStaff.phone"
              class="focus:outline-none"
              type="text"
              placeholder="Phone Number"
              aria-autocomplete="no"
            ></t-input>
          </div> -->
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff Role</label
              >
              <t-select
                v-model="newStaff.role"
                class="focus:outline-none"
                type="text"
                :disable="loading"
                :options="[
                  'Super Admin',
                  'Admin',
                  'Travel Manager',
                  'Travel Consultant'
                ]"
              ></t-select>
            </div>
          </div>
          <div class="flex items-center mt-5 justify-end">
            <t-button
              :disable="loading"
              class="flex items-center justify-center space-x-4"
            >
              <span>Add Staff</span>
              <spinner v-if="loading"></spinner>
            </t-button>
          </div>
        </form>
      </div>
    </t-modal>
    <t-modal v-model="editStaffDialog">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">Edit Staff</h4>
        <p class="text-sm font-light">Edit detail of a staff here</p>

        <form class="py-2">
          <error-text
            :message="error.message"
            :error="error.status"
          ></error-text>
          <div class="mt-4 grid md:grid-cols-2 grid-cols-1 gap-8">
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff First Name</label
              >
              <t-input
                v-model="editStaff.firstName"
                :disable="loadingEditStaff"
                class="focus:outline-none"
                type="text"
                placeholder="First Name"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff Last Name</label
              >
              <t-input
                v-model="editStaff.lastName"
                class="focus:outline-none"
                :disable="loadingEditStaff"
                type="text"
                placeholder="Last Name"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff Email</label
              >
              <t-input
                v-model="editStaff.email"
                class="focus:outline-none"
                type="text"
                :disable="loadingEditStaff"
                placeholder="Staff Email"
                aria-autocomplete="no"
              ></t-input>
            </div>
            <!-- <div class="form-control">
            <label
              class="text-xs font-semibold text-gray-800 mb-2 block"
              for="name"
              >Staff Phone Number</label
            >
            <t-input
              v-model="editStaff.phone"
              class="focus:outline-none"
              type="text"
              placeholder="Phone Number"
              aria-autocomplete="no"
            ></t-input>
          </div> -->
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff Role</label
              >
              <t-select
                v-model="editStaff.role"
                class="focus:outline-none"
                type="text"
                :disable="loadingEditStaff"
                :options="[
                  'Super Admin',
                  'Admin',
                  'Travel Manager',
                  'Travel Consultant'
                ]"
              ></t-select>
            </div>
            <div class="form-control">
              <label
                class="text-xs font-semibold text-gray-800 mb-2 block"
                for="name"
                >Staff Status</label
              >
              <t-select
                v-model="editStaff.status"
                class="focus:outline-none"
                type="text"
                :disable="loadingEditStaff"
                :options="['Active', 'Suspend']"
              ></t-select>
            </div>
          </div>
          <div class="flex items-center mt-5 justify-end">
            <t-button
              @click.prevent="deleteStaff"
              :disable="deletingStaff"
              class="flex items-center border-red-600 mr-5 bg-red-600 justify-center space-x-4"
            >
              <span>Delete Staff</span>
              <spinner v-if="deletingStaff"></spinner>
            </t-button>
            <t-button
              @click.prevent="editStaffMethod"
              :disable="loadingEditStaff"
              class="flex items-center justify-center space-x-4"
            >
              <span>Update Staff</span>
              <spinner v-if="loadingEditStaff"></spinner>
            </t-button>
          </div>
        </form>
      </div>
    </t-modal>
    <page-title>Staffs</page-title>
    <page-subtitle>Here's the staff list</page-subtitle>
    <div class="mt-5">
      <div class="flex rounded space-x-2 items-center justify-between mb-5">
        <div
          class="search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"
        >
          <img
            class="w-5 h-5"
            src="../../assets/svgs/search.svg"
            alt="search"
          />
          <input
            v-model="search"
            class="border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0"
            type="text"
            placeholder="Search by name, email..."
          />
        </div>
        <t-button
          @click="() => (createStaffDialog = true)"
          class="text-sm block w-40 py-2"
          >Add Staff</t-button
        >
      </div>
      <div class="bg-white p-5 rounded">
        <div
          class="tabs flex md:flex-row flex-col md:space-x-3 text-xs md:text-sm font-semibold border-b border-gray-200"
        >
          <span
            @click="changeActiveTab('all')"
            :class="
              `py-3 px-5 cursor-pointer ${
                activeTab === 'all' ? 'bg-primary text-white' : ''
              } rounded-tl`
            "
            >All Staff</span
          >
          <span
            @click="changeActiveTab('consultant')"
            :class="
              `py-3 px-5 cursor-pointer ${
                activeTab === 'consultant' ? 'bg-primary text-white' : ''
              }`
            "
            >Travel Consultant</span
          >
          <span
            @click="changeActiveTab('manager')"
            :class="
              `py-3 px-5 cursor-pointer ${
                activeTab === 'manager' ? 'bg-primary text-white' : ''
              }`
            "
            >Travel Manager</span
          >
        </div>
        <div v-if="loadingStaff" class="flex items-center justify-center mt-5">
          <spinner color="text-primary" />
        </div>

        <div v-else class="task-list grid grid-cols-1 mt-5">
          <div class="flex flex-col w-full">
            <div class="overflow-x-auto">
              <table class="table-fixed overflow-hidden min-h-full min-w-full">
                <thead
                  class="bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"
                >
                  <tr>
                    <th class="py-3 px-3">Name</th>
                    <th class="py-3 px-3">Email</th>
                    <!-- <th class="py-3 px-3">Phone Number</th> -->
                    <th class="py-3 px-3">Role</th>
                    <th class="py-3 px-3">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="staff in staffsList"
                    :key="staff.id"
                    @click="showUpdateModal(staff)"
                    class="text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded"
                  >
                    <td style="width: max-content" class="px-3 py-4">
                      {{ staff.firstName }} {{ staff.lastName }}
                    </td>
                    <td class="px-3 py-4" style="width: max-content">
                      {{ staff.email }}
                    </td>
                    <!-- <td  style="width: max-content">+234(0)8160730668</td> -->
                    <td class="px-3 py-4 capitalize" style="width: max-content">
                      {{ staff.role | getRole }}
                    </td>
                    <td class="px-3 py-4" style="width: max-content">
                      <span
                        :class="
                          staff.status === 'suspended'
                            ? 'bg-red-500'
                            : 'bg-green-500'
                        "
                        class="font-semibold py-2 px-3 text-white rounded"
                        >{{ staff.status }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="!loadingStaff" class="mt-10 flex flex-wrap">
          <span
            v-for="l in meta.pages"
            :class="
              `p-2 ${
                meta.offset / (l - 1) === (meta.limit || 0)
                  ? 'text-white bg-primary'
                  : 'text-black'
              } py-1 cursor-pointer rounded shadow m-2`
            "
            @click="getAllStaff((l - 1) * meta.limit)"
            :key="l"
          >
            {{ l }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import userApi from '../../api/userMgt'
import Spinner from '../../components/Utils/Spinner.vue'
import ErrorText from '../../components/Typography/ErrorText.vue'

export default {
  name: 'Staff',
  components: { PageTitle, PageSubtitle, Spinner, ErrorText },
  data() {
    return {
      search: '',
      activeTab: 'all',
      createStaffDialog: false,
      editStaffDialog: false,
      loading: false,
      error: {},
      staffs: [],
      meta: {},
      newStaff: {
        name: '',
        firstName: '',
        lastName: '',
        phone: '',
        role: '',
        email: ''
      },
      editStaff: {},
      loadingEditStaff: false,
      deletingStaff: false,
      loadingStaff: false
    }
  },
  created() {
    this.getAllStaff()
  },
  filters: {
    getRole: function(value) {
      if (!value) return ''
      switch (value.toString().toLowerCase()) {
        case 'admin':
          return 'admin'

        case 'manager':
          return 'travel manager'

        case 'consultant':
          return 'travel consultant'

        case 'superadmin':
          return 'super admin'

        default:
          return 'admin'
      }
    }
  },
  computed: {
    staffsList() {
      if (this.activeTab === 'all') {
        return this.staffs.filter(c =>
          this.search
            .toLowerCase()
            .split(' ')
            .every(v => {
              const fullName = c.firstName + ' ' + c.lastName + ' ' + c.email
              return fullName.toLowerCase().includes(v)
            })
        )
      }
      if (this.activeTab !== 0) {
        return this.staffs
          .filter(c => c.role === this.activeTab)
          .filter(c =>
            this.search
              .toLowerCase()
              .split(' ')
              .every(v => {
                const fullName = c.firstName + ' ' + c.lastName + ' ' + c.email

                return fullName.toLowerCase().includes(v)
              })
          )
      }
      return this.staffs
    }
  },
  methods: {
    changeActiveTab(n) {
      this.activeTab = n
    },
    async getAllStaff(offset) {
      this.loadingStaff = true
      const res = await userApi.user().getAll({ offset })
      this.staffs = res.data.data
      this.meta = res.data.meta
      this.meta.pages = Math.ceil(this.meta.total / this.meta.limit)
      this.loadingStaff = false
    },

    async editStaffMethod() {
      this.loadingEditStaff = true
      const res = await userApi.user().updateUserStatus({
        userId: this.editStaff._id,
        status: this.editStaff.status === 'Active' ? 'active' : 'suspended'
      })
      if (res.error) {
        this.loading = false
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.loadingEditStaff = false
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.getAllStaff()
      this.loadingEditStaff = false
      this.editStaffDialog = false
    },

    async deleteStaff() {
      this.deletingStaff = true
      const res = await userApi.user().deleteAUser({
        userId: this.editStaff._id
      })
      if (res.error) {
        this.loading = false
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.deletingStaff = false
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.getAllStaff()
      this.deletingStaff = false
      this.editStaffDialog = false
    },

    async addNewStaff() {
      this.loading = true
      this.error.status = false

      if (
        this.newStaff.firstName === '' ||
        this.newStaff.lastName === '' ||
        this.newStaff.role === '' ||
        this.newStaff.email === ''
      ) {
        this.error.status = true
        this.error.message = 'Please fill an fields'
        this.loading = false
        return
      }

      let role = ''

      switch (this.newStaff.role.toString().toLowerCase()) {
        case 'admin':
          role = 'admin'
          break

        case 'travel manager':
          role = 'manager'
          break

        case 'travel consultant':
          role = 'consultant'
          break

        case 'super admin':
          role = 'superadmin'
          break

        default:
          role = 'admin'
          break
      }

      const res = await userApi.user().addUser({
        email: this.newStaff.email,
        role,
        firstName: this.newStaff.firstName,
        lastName: this.newStaff.lastName
      })
      if (res.error) {
        this.loading = false
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })
      this.getAllStaff()
      this.newStaff = {}
      this.createStaffDialog = false
      this.loading = false
    },
    showUpdateModal(n) {
      this.editStaff = {
        ...n,
        role: this.getRole(n.role),
        status: n.status === 'active' ? 'Active' : 'Suspend'
      }
      this.editStaffDialog = true
    },
    getRole(role) {
      switch (role.toString().toLowerCase()) {
        case 'admin':
          return 'Admin'

        case 'manager':
          return 'Travel Manager'

        case 'consultant':
          return 'Travel Consultant'

        case 'superadmin':
          return 'Super Admin'

        default:
          return 'Admin'
      }
    }
  }
}
</script>

<style></style>
